var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Recebimento de contas")]),_c('v-tooltip',{attrs:{"bottom":"","max-width":_vm.isMobile ? '' : 400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"30","color":"black","right":""}},'v-icon',attrs,false),on),[_vm._v("info")])]}}])},[_c('span',{staticClass:"articulat-medium",staticStyle:{"font-size":"16px"}},[_c('b',[_vm._v("Total recebido de curso no mês")]),_vm._v(" - filtrar o mês desejado, deixar selecionado apenas curso, não desmarcar nenhuma forma de pagamento e nenhum status de parcela, nem de contrato. ")])])],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('period-selector-component',{attrs:{"prop-title":"Data de recebimento"},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',[_c('SaveButton',{ref:"loadingButton",attrs:{"prop-show-voltar":false,"propSubmitLabel":"Buscar","propTooltipMsg":"Para visualizar totalizadores, arraste a tela até embaixo e clique em carregar mais."},on:{"submit":function($event){return _vm.buscar()}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-row',[(_vm.totals.por_financeiro_categoria)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"propAppend":false,"prop-title":"Por categoria","prop-items":_vm.totals.por_financeiro_categoria},scopedSlots:_vm._u([{key:"item.temp_valor_recebido",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["Valor recebido"]))+" ")]}}],null,true)})],1):_vm._e(),(_vm.totals.por_forma_pagamento)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"propAppend":false,"prop-title":"Por forma de pagamento","prop-items":_vm.totals.por_forma_pagamento},scopedSlots:_vm._u([{key:"item.temp_valor_recebido",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["Valor recebido"]))+" ")]}}],null,true)})],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',[(_vm.totals.por_status)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"propAppend":false,"prop-title":"Por Status","prop-items":_vm.totals.por_status},scopedSlots:_vm._u([{key:"item.temp_valor_recebido",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["Valor recebido"]))+" ")]}}],null,true)})],1):_vm._e(),(_vm.totals.por_status_contrato)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"propAppend":false,"prop-title":"Por Status do contrato","prop-items":_vm.totals.por_status_contrato},scopedSlots:_vm._u([{key:"item.temp_valor_recebido",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["Valor recebido"]))+" ")]}}],null,true)})],1):_vm._e()],1)],1)],1),(_vm.totals.por_status)?_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propTableName":"list_recebimentos","propItemKey":"row_num","propSum":""},scopedSlots:_vm._u([{key:"item.entidade_nome",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.entidade_nome)),"propObjectId":item.entidade_id,"propToolName":item.entidade_tipo}})]}},{key:"item.caixa_id",fn:function(ref){
var item = ref.item;
return [(item.caixa_id)?[_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.caixa_nome)),"propObjectId":item.caixa_id,"propToolName":"Caixa"}})]:_vm._e()]}},{key:"item.vencimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.vencimento))+" ")]}},{key:"item.data_pagamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_pagamento))+" ")]}},{key:"item.data_emissao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_emissao))+" ")]}},{key:"item.observacao",fn:function(ref){
var value = ref.value;
return [_c('text-expandable-component',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.forma_de_pagamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormaDePagamento(item.forma_de_pagamento))+" ")]}},{key:"item.contrato_status_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:_vm._.find(_vm.getStatusDoContratoOptions(), function (i) { return i.id == item.contrato_status_id; }).color,attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm._.find(_vm.getStatusDoContratoOptions(), function (i) { return i.id == item.contrato_status_id; }).name)+" ")])]}},{key:"item.financeiro_parcela_status_nome",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:_vm.getStatusFinanceiroParcelaStatus(item.financeiro_parcela_status_id).color,attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getStatusFinanceiroParcelaStatus(item.financeiro_parcela_status_id).name)+" ")])]}},{key:"item.valor_integral",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item.valor_integral))+" ")]}},{key:"item.valor_atualizado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item.valor_atualizado))+" ")]}},{key:"item.valor_com_desconto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item.valor_com_desconto))+" ")]}},{key:"item.valor_pago",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item.valor_pago))+" ")]}},{key:"item.total_pago",fn:function(ref){
var item = ref.item;
return [_c('FinanceiroValorPagoTextComponent',{attrs:{"propItem":_vm.getFinanceiroValorPagoTextObj(item)}})]}},{key:"item.aluno_cpf",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatCpfCnpj")(value))+" ")]}},{key:"item.responsavel_cpf",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatCpfCnpj")(value))+" ")]}},{key:"item.aluno_contatos",fn:function(ref){
var item = ref.item;
return _vm._l((item.aluno_contatos),function(contato,idx){return _c('div',{key:idx},[(contato.observation)?_c('span',[_vm._v(_vm._s(contato.observation)+" -")]):_vm._e(),_vm._v(" "+_vm._s(contato.phone)),_c('br')])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.allowEdit)?_c('v-icon',{on:{"click":function($event){return _vm.routeEdit(item.id)}}},[_vm._v("mdi-eye")]):_vm._e()]}}],null,true)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }